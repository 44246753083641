<template>
  <v-row class="pb-3 pt-1" no-gutters>
    <v-col class="px-3 mb-3" cols="12" sm="4" md="3">
      <v-text-field
        v-model="params.name"
        :label="$t('filters.name')"
        prepend-inner-icon="search"
        clearable
        hide-details
        single-line
        @input="handleDebounce"
      />
    </v-col>
  </v-row>
</template>

<script>
import filterMixin from '@/mixins/filter-mixin';

export default {
  name: 'ContactFilter',

  mixins: [filterMixin],

  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped></style>
