<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page || 50],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="decoratedItems"
    :mobile-breakpoint="0"
    :page="pagination.current_page || 1"
    :server-items-length="pagination.total || -1"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    :item-class="() => 'clickable'"
    disable-sort
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.no="{ index, item }">
      <v-progress-circular
        v-if="$store.getters.loading[`get:api/clients/${item.id}`]"
        color="primary"
        size="16"
        width="2"
        indeterminate
      />
      <span v-else> {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}. </span>
    </template>

    <template v-slot:item.name="{ item }">
      <span class="text-h6" :class="getExclamationClass(item)">!</span>
      {{ item.name || item.full_name }}
    </template>

    <template v-slot:item.turnover="{ item }">
      <span> {{ item.total_sales_w_vat | currency }}&nbsp;/&nbsp; </span>
      <span :class="{ 'alert-text': item.total_debt_w_vat > 0 }">
        {{ item.total_debt_w_vat | currency }}
      </span>
    </template>

    <template v-slot:item.projects="{ item }">
      <span> {{ +item.total_projects }}&nbsp;/&nbsp; </span>
      <span :class="{ 'text-success': +item.total_active_projects > 0 }">
        {{ +item.total_active_projects }}
      </span>
    </template>

    <template v-slot:item.tasks="{ item }">
      <span>
        {{ item.incomplete_user_stories }}
      </span>
      <span>&nbsp;/&nbsp;</span>
      <span :class="{ 'alert-text': item.incomplete_issues > 0 }">
        {{ item.incomplete_issues }}
      </span>
    </template>

    <template v-slot:item.actions="{ item }">
      <ContactTableActionMenu :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.email>
          <a :href="`mailto:${item.email}`" class="link">{{ item.email }}</a>
        </template>

        <template v-slot:item.phone>
          <a :href="`tel:${item.phone}`" class="link">{{ item.phone }}</a>
        </template>

        <template v-slot:item.active>
          <v-icon v-if="item.active" small>mdi-check-bold</v-icon>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import ContactTableActionMenu from '@/components/tables/ContactTableActionMenu';

export default {
  name: 'ContactTable',

  components: {
    ContactTableActionMenu,
    BaseExpandedTableRow,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    decoratedItems() {
      return this.items.map((item) => ({
        ...item,
        incomplete_user_stories:
          item.incomplete_user_stories?.reduce(
            (sum, project) => sum + +project.incomplete_user_stories,
            0,
          ) || 0,
        incomplete_issues:
          item.incomplete_issues?.reduce((sum, project) => sum + +project.incomplete_issues, 0) ||
          0,
      }));
    },

    headers() {
      return [
        { text: this.$t('general.no'), value: 'no', hidden: 'smAndDown' },
        {
          text: this.$t('general.title'),
          value: 'name',
          requiredPermissions: ['clients.existing.view.name'],
        },
        {
          text: this.$t('general.turnover'),
          value: 'turnover',
          hidden: 'smAndDown',
          requiredPermissions: ['clients.existing.view.finance_info'],
        },
        {
          text: this.$t('general.projects'),
          value: 'projects',
          hidden: 'smAndDown',
          requiredPermissions: ['clients.existing.view.project_info'],
        },
        {
          text: `${this.$t('general.tasks')} / ${this.$t('general.issues')}`,
          value: 'tasks',
          hidden: 'smAndDown',
          permissions: ['clients.existing.view.project_info'],
        },
        {
          value: 'actions',
        },
      ];
    },
  },

  methods: {
    getExclamationClass(supplier) {
      const THRESHOLD = 3;
      const { quality, timeliness, support } = supplier;
      const array = [quality, timeliness, support];

      const lessThanThreshold = array.find((item) => item < THRESHOLD);
      if (lessThanThreshold) {
        return { 'error--text': true };
      }

      const equalsThreshold = array.find((item) => item === THRESHOLD);
      if (equalsThreshold) {
        return { 'warning--text': true };
      }

      return { 'd-none': true };
    },
  },
};
</script>
