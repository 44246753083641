var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-action-menu-wrapper"},[(_vm.deviceType === 'mdAndMouse')?_c('div',{staticClass:"desktop-and-mouse-actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewContactHistory.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("history")])],1)]}}],null,false,3517897803)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('clients.general.change_history'))+" ")])]),(
        _vm.$can([
          'clients.existing.make_document_actions',
          'clients.existing.make_invoice_actions',
          'clients.existing.make_project_actions' ])
      )?_c('v-menu',{attrs:{"bottom":"","offset-y":""},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2497972063)},[_c('v-list',{staticStyle:{"display":"grid"}},[(_vm.$can(['clients.existing.make_document_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.documents')))])],1)]}}],null,false,782802827)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createSupplierAgreement}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('documents.modal_titles.new_supplier_agreement')))])],1),_c('v-list-item',{on:{"click":_vm.viewDocuments}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.supplier_agreement_list')))])],1)],1)],1):_vm._e(),(_vm.$can(['clients.existing.make_invoice_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.invoices')))])],1)]}}],null,false,1993109397)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createInvoice}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('clients.invoices.new_invoice'))+" ")])],1),_c('v-list-item',{on:{"click":_vm.viewInvoices}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('clients.invoices.invoice_list')))])],1)],1)],1):_vm._e(),(_vm.$can(['clients.existing.make_project_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.projects')))])],1)]}}],null,false,2230653937)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createProject}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.modal_titles.new_project')))])],1),_c('v-list-item',{on:{"click":_vm.viewProjects}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('clients.projects.project_list')))])],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteContact(_vm.item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_c('div',{staticClass:"mobile-or-touch-actions"},[(
        _vm.$can([
          'clients.existing.make_document_actions',
          'clients.existing.make_invoice_actions',
          'clients.existing.make_project_actions' ])
      )?_c('v-menu',{attrs:{"bottom":"","offset-y":""},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2497972063)},[_c('v-list',{staticStyle:{"display":"grid"}},[(_vm.$can(['clients.existing.make_document_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.documents')))])],1)]}}],null,false,782802827)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createSupplierAgreement}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('documents.modal_titles.new_supplier_agreement')))])],1),_c('v-list-item',{on:{"click":_vm.viewDocuments}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.supplier_agreement_list')))])],1)],1)],1):_vm._e(),(_vm.$can(['clients.existing.make_invoice_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.invoices')))])],1)]}}],null,false,1993109397)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createInvoice}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('clients.invoices.new_invoice'))+" ")])],1),_c('v-list-item',{on:{"click":_vm.viewInvoices}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('clients.invoices.invoice_list')))])],1)],1)],1):_vm._e(),(_vm.$can(['clients.existing.make_project_actions']))?_c('v-menu',{attrs:{"left":"","open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"full-width",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.projects')))])],1)]}}],null,false,2230653937)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createProject}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.modal_titles.new_project')))])],1),_c('v-list-item',{on:{"click":_vm.viewProjects}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('clients.projects.project_list')))])],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"full-width",on:{"click":_vm.viewContactHistory}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("history")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('clients.general.change_history')))])],1),_c('v-divider'),_c('v-list-item',{staticClass:"full-width",on:{"click":_vm.editContact}},[_c('v-list-item-action',[(_vm.$store.getters.loading[("get:api/clients/" + (_vm.item.id))])?_c('v-progress-circular',{attrs:{"color":"primary","size":"24","width":"3","indeterminate":""}}):_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.controls.edit')))])],1),_c('v-list-item',{staticClass:"full-width",on:{"click":function($event){return _vm.deleteContact(_vm.item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('general.controls.delete')))])],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }