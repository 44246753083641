<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchContacts(contactFilterParams)">
        {{ $t('general.common_contact_info.contacts') }}
      </v-tab>

      <v-tab-item>
        <ContactFilter
          :filter-params="contactFilterParams"
          @change="$router.push({ name: 'contacts', query: $event })"
        />

        <ContactTable
          :items="contacts"
          :loading="$store.getters.loading['get:api/clients']"
          :pagination="contactPagination"
          @click:row="$router.push({ name: 'editContact', params: { contactId: $event.id } })"
          @click:history="
            $router.push({ name: 'contactHistory', params: { contactId: $event.id } })
          "
          @delete="deleteContact"
          @update:page="onPageChange"
        />
      </v-tab-item>
    </v-tabs>

    <router-view />

    <BasePrimaryActionButton v-if="$can(['clients.existing.create'])" @click="createNewClient" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContactTable from '@/components/tables/ContactTable';
import {
  getDefaultClientContactFormItem,
  getDefaultContactFilterParams,
} from '@/store/modules/contacts-module';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import ContactFilter from '@/components/filters/ContactFilter';
import { getSanitizedFilterParams } from '@/util/filter-params';

export default {
  name: 'Contacts',

  components: {
    ContactFilter,
    BasePrimaryActionButton,
    ContactTable,
  },

  computed: {
    ...mapState('contacts', ['contacts', 'contactPagination', 'contactFilterParams']),
  },

  created() {
    this.fetchContacts(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.contactFilterParams) !== JSON.stringify(params) &&
      to.name === 'contacts'
    ) {
      this.fetchContacts(to.query);
    }
    next();
  },

  methods: {
    ...mapMutations('contacts', ['SET_NEW_CONTACT']),
    ...mapActions('contacts', ['fetchContacts', 'deleteContact']),

    onPageChange(page) {
      const query = { ...this.contactFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'contacts', query });
    },

    resetContactFilters() {
      const defaultFilters = getDefaultContactFilterParams();
      if (JSON.stringify(defaultFilters) === JSON.stringify(this.contactFilterParams)) {
        return;
      }
      this.$router.push({ name: 'contacts', query: defaultFilters });
    },

    createNewClient() {
      // because same form/store variable is used for different variations, e.g. supplier and client
      this.SET_NEW_CONTACT(getDefaultClientContactFormItem());
      this.$router.push({ name: 'createContact' });
    },
  },
};
</script>
