var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.per_page || 50],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedItems,"mobile-breakpoint":0,"page":_vm.pagination.current_page || 1,"server-items-length":_vm.pagination.total || -1,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
  var index = ref.index;
  var item = ref.item;
return [(_vm.$store.getters.loading[("get:api/clients/" + (item.id))])?_c('v-progress-circular',{attrs:{"color":"primary","size":"16","width":"2","indeterminate":""}}):_c('span',[_vm._v(" "+_vm._s(index + 1 + (_vm.pagination.current_page - 1) * _vm.pagination.per_page)+". ")])]}},{key:"item.name",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-h6",class:_vm.getExclamationClass(item)},[_vm._v("!")]),_vm._v(" "+_vm._s(item.name || item.full_name)+" ")]}},{key:"item.turnover",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total_sales_w_vat))+" /  ")]),_c('span',{class:{ 'alert-text': item.total_debt_w_vat > 0 }},[_vm._v(" "+_vm._s(_vm._f("currency")(item.total_debt_w_vat))+" ")])]}},{key:"item.projects",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(+item.total_projects)+" /  ")]),_c('span',{class:{ 'text-success': +item.total_active_projects > 0 }},[_vm._v(" "+_vm._s(+item.total_active_projects)+" ")])]}},{key:"item.tasks",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.incomplete_user_stories)+" ")]),_c('span',[_vm._v(" / ")]),_c('span',{class:{ 'alert-text': item.incomplete_issues > 0 }},[_vm._v(" "+_vm._s(item.incomplete_issues)+" ")])]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('ContactTableActionMenu',{attrs:{"item":item}})]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.email",fn:function(){return [_c('a',{staticClass:"link",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]},proxy:true},{key:"item.phone",fn:function(){return [_c('a',{staticClass:"link",attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]},proxy:true},{key:"item.active",fn:function(){return [(item.active)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-bold")]):_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }