<template>
  <div class="base-action-menu-wrapper">
    <div v-if="deviceType === 'mdAndMouse'" class="desktop-and-mouse-actions">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop="viewContactHistory">
            <v-icon>history</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('clients.general.change_history') }}
        </span>
      </v-tooltip>
      <v-menu
        v-if="
          $can([
            'clients.existing.make_document_actions',
            'clients.existing.make_invoice_actions',
            'clients.existing.make_project_actions',
          ])
        "
        bottom
        offset-y
        @click.native.stop
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list style="display: grid">
          <v-menu
            v-if="$can(['clients.existing.make_document_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.documents') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createSupplierAgreement">
                <v-list-item-title>{{
                  $t('documents.modal_titles.new_supplier_agreement')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewDocuments">
                <v-list-item-title>{{ $t('general.supplier_agreement_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$can(['clients.existing.make_invoice_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.invoices') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createInvoice">
                <v-list-item-title>
                  {{ $t('clients.invoices.new_invoice') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewInvoices">
                <v-list-item-title>{{ $t('clients.invoices.invoice_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$can(['clients.existing.make_project_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.projects') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createProject">
                <v-list-item-title>{{ $t('general.modal_titles.new_project') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewProjects">
                <v-list-item-title>{{ $t('clients.projects.project_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-menu>
      <v-btn icon @click.stop="deleteContact(item)">
        <v-icon>delete</v-icon>
      </v-btn>
    </div>

    <div v-else class="mobile-or-touch-actions">
      <v-menu
        v-if="
          $can([
            'clients.existing.make_document_actions',
            'clients.existing.make_invoice_actions',
            'clients.existing.make_project_actions',
          ])
        "
        bottom
        offset-y
        @click.native.stop
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list style="display: grid">
          <v-menu
            v-if="$can(['clients.existing.make_document_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.documents') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createSupplierAgreement">
                <v-list-item-title>{{
                  $t('documents.modal_titles.new_supplier_agreement')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewDocuments">
                <v-list-item-title>{{ $t('general.supplier_agreement_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$can(['clients.existing.make_invoice_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.invoices') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createInvoice">
                <v-list-item-title>
                  {{ $t('clients.invoices.new_invoice') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewInvoices">
                <v-list-item-title>{{ $t('clients.invoices.invoice_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$can(['clients.existing.make_project_actions'])"
            left
            open-on-hover
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" class="full-width" @click.stop>
                <v-list-item-action>
                  <v-icon>keyboard_arrow_left</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('general.projects') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="createProject">
                <v-list-item-title>{{ $t('general.modal_titles.new_project') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewProjects">
                <v-list-item-title>{{ $t('clients.projects.project_list') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item class="full-width" @click="viewContactHistory">
            <v-list-item-action>
              <v-icon>history</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('clients.general.change_history') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item class="full-width" @click="editContact">
            <v-list-item-action>
              <v-progress-circular
                v-if="$store.getters.loading[`get:api/clients/${item.id}`]"
                color="primary"
                size="24"
                width="3"
                indeterminate
              />
              <v-icon v-else>edit</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('general.controls.edit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="full-width" @click="deleteContact(item)">
            <v-list-item-action>
              <v-icon>delete</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('general.controls.delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { getDefaultInvoiceFormItem } from '@/store/modules/invoices-module';
import { getDefaultSupplierAgreementFormItem } from '@/store/modules/supplier-agreements-module';
import { getDefaultProjectFormItem } from '@/store/modules/projects-module';

export default {
  name: 'ContactTableActionMenu',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('settings', ['isTouchDevice']),
    ...mapState('invoices', ['invoiceFilterParams']),

    deviceType() {
      if (this.isTouchDevice || this.$vuetify.breakpoint.smAndDown) {
        return 'smOrTouch';
      }

      return 'mdAndMouse';
    },
  },

  methods: {
    ...mapMutations('invoices', ['SET_NEW_INVOICE']),
    ...mapMutations('supplierAgreements', ['SET_NEW_SUPPLIER_AGREEMENT']),
    ...mapMutations('projects', ['SET_NEW_PROJECT']),
    ...mapActions('contacts', ['deleteContact']),

    editContact() {
      this.$router.push({ name: 'editContact', params: { contactId: this.item.id } });
    },

    viewContactHistory() {
      this.$router.push({ name: 'contactHistory', params: { contactId: this.item.id } });
    },

    viewDocuments() {
      this.$router.push({
        name: 'supplierAgreements',
        query: {
          supplier_id: this.item.id,
        },
      });
    },

    createSupplierAgreement() {
      this.SET_NEW_SUPPLIER_AGREEMENT({
        ...getDefaultSupplierAgreementFormItem(),
        suppler: this.item,
        supplier_id: this.item.id,
      });
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'uploading-document': 'supplier_agreement',
        },
      });
    },

    viewInvoices() {
      this.$router.push({ name: 'invoices', query: this.invoiceFilterParams });
    },

    createInvoice() {
      this.SET_NEW_INVOICE({
        ...getDefaultInvoiceFormItem(),
        client_id: this.item.id,
        client: this.item,
      });
      this.$router.push({ name: 'createContactInvoice' });
    },

    viewProjects() {
      this.$router.push({
        name: 'projectsList',
        query: {
          client_id: this.item.id,
        },
      });
    },

    createProject() {
      this.SET_NEW_PROJECT({
        ...getDefaultProjectFormItem(),
        client_id: this.item.id,
        client: this.item,
      });
      this.$router.push({ name: 'createProject' });
    },
  },
};
</script>

<style scoped></style>
